import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import './QuestInfo.css';

import { showPopup } from './Popup';
import Timer from './Timer';

import { QuestTarget } from '../data/types';
import { verifyQuest } from '../data/api';

enum QuestStatus {
  Default,
  Connecting,
  Connected,
  Verifying,
  Completing,
  Completed,
}

interface QuestInfoProps {
  id: string;
  link: string;
  title: string;
  target: QuestTarget;
  cost: number;
  completed: boolean;
  completedCallback: (id: string) => void;
  end?: Date;
}

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function QuestInfo(props: QuestInfoProps) {
  const questKey = `quest_${props.id}`;

  const [checked, setChecked] = useState(localStorage.getItem(questKey) === "true");
  const [status, setStatus] = useState<QuestStatus>(
    props.completed 
    ? QuestStatus.Completed 
    : (needAdditionalAction() && checked 
      ? QuestStatus.Connected
      : QuestStatus.Default));

  const verifyMutation = useMutation({
    mutationFn: () => {
      setStatus(QuestStatus.Verifying);
      return verifyQuest(telegramId, props.id);
    },
    onSuccess: (res) => {
      if (res === undefined) {
        throw new Error('undefined result');
      }

      if (res) {
        localStorage.removeItem(questKey);
        setStatus(QuestStatus.Completing);
        showPopup(`You got +${props.cost} HAT points`);
      } else {
        setStatus(QuestStatus.Connected);
        showPopup(`Task is not completed`);
      }
    },
    onError: (error) => {
      console.error(`verifyQuest error: ${error}`);
    },
  });

  const completeButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStatus(QuestStatus.Completed);
    props.completedCallback(props.id);
  };

  const connectButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStatus(QuestStatus.Connecting);
    e.currentTarget.classList.add('connecting');
    window.open(props.link);
    setTimeout(
      function (classList) {
        localStorage.setItem(questKey, "true");
        setChecked(true);

        setStatus(QuestStatus.Connected);
        classList.remove('connecting');
        classList.add('connected');
      },
      3000,
      e.currentTarget.classList
    );
  };

  function onVerify() {
    if (!needAdditionalAction() || checked) {
      verifyMutation.mutate();
    }
  }

  const getImagePath = (target: QuestTarget) => {
    return `/images/${QuestTarget[target]}.png`;
  };

  const getTargetActionDescription = (target: QuestTarget) => {
    switch (target) {
      case QuestTarget.Website:
        return 'Visit';
      case QuestTarget.Discord:
        return 'Join';
      case QuestTarget.Twitter:
        return 'Subscribe';
      case QuestTarget.Game:
        return 'Download';
      case QuestTarget.Telegram:
        return 'Join';
      default:
        break;
    }
    return 'Connect';
  };

  function needAdditionalAction() {
    return props.target !== QuestTarget.Frens && props.target !== QuestTarget.HatPoints;
  }

  return (
    <>
      <div
        className={
          status === QuestStatus.Completed
            ? 'quest-border completed'
            : status === QuestStatus.Completing
            ? 'quest-border completing'
            : 'quest-border'
        }
      >
        <div className="quest-header">
          <h2 className="quest-header-title">Daily</h2>
          <h4 className="quest-time">
            {props.end && (
              <div>
                <span className="quest-time-title">reset in: </span>
                <Timer time={props.end} showUnits={true} expirationMessage="EXPIRED" expireCallback={() => {}} />
              </div>
            )}
          </h4>
        </div>
        <div className="quest-content">
          <div className="quest-icon-holder">
            <div className="quest-icon-fit-holder horizontal-center">
              <img className="quest-icon" src={getImagePath(props.target)} alt="" />
            </div>
          </div>
          <div className="quest-title-holder">
            <h1 className="quest-title">{props.title}</h1>
            <div className="quest-coins-holder">
              <div className="quest-coins-value-holder">
                <p className="quest-coins">
                  <span>+</span>
                  {props.cost.toLocaleString()}
                </p>
              </div>
              <div className="quest-coins-icon-holder">
                <img className="quest-coins-icon" src="/images/mask-group.png" alt="" />
              </div>
            </div>
          </div>
          {status === QuestStatus.Completed && (
            <div className="quest-progress">
              <div className="quest-progress-circle">
                <div className="vertical-center">
                  <div className="quest-progress-ok-holder horizontal-center">
                    <img className="quest-progress-ok" src="/images/ok.png" alt="ok" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {status !== QuestStatus.Completed && (
          <div className="quest-buttons-holder">
            {status === QuestStatus.Completing ? (
              <button onClick={completeButtonClick} className="quest-buttons completed">
                Complete
              </button>
            ) : (
              <div className="main-quest-buttons-holder">
                {needAdditionalAction() && (
                  <button 
                    className={status === QuestStatus.Connecting 
                      ? "quest-buttons connecting" 
                      : status === QuestStatus.Connected
                        ? "quest-buttons connected"
                        : "quest-buttons"  } 
                    onClick={connectButtonClick}>
                    {getTargetActionDescription(props.target)}
                  </button>
                )}
                {status === QuestStatus.Verifying ? (
                  <div className={'loader-spinner'}></div>
                ) : (
                  <button
                    onClick={onVerify}
                    className={status === QuestStatus.Connected ? 'quest-buttons verifying' : 'quest-buttons'}
                  >
                    Verify
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default QuestInfo;
