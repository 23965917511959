import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import './QuestsView.css';

import QuestInfo from '../../QuestInfo';

import { QuestData } from '../../../data/types';
import { questsOptions } from '../../../data/queryOptions';

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function QuestsView() {
  const { data: quests, isPending, isError, refetch: getQuestsRefetch } = useQuery(questsOptions(telegramId));

  const [notCompletedQuests, setNotCompletedQuests] = useState<QuestData[]>([]);
  const [completedQuests, setCompletedQuests] = useState<QuestData[]>([]);

  async function splitQuests() {
    if (!quests) {
      return;
    }

    const notСompleted: QuestData[] = [];
    const completed: QuestData[] = [];
    for (let i = 0; i < quests!.length; i++) {
      if (quests[i].completed) {
        completed.push(quests![i]);
      } else {
        notСompleted.push(quests![i]);
      }
    }

    setNotCompletedQuests(notСompleted);
    setCompletedQuests(completed);
  }

  function onQuestCompleted(id: string) {
    notCompletedQuests.forEach((item, index) => {
      if (item.id === id) {
        notCompletedQuests.splice(index, 1);
        setNotCompletedQuests([...notCompletedQuests]);

        completedQuests.push(item);
        setCompletedQuests([...completedQuests]);
      }
    });
    getQuestsRefetch();
  }

  useEffect(() => {
    if (quests) {
      splitQuests();
    }
  }, [quests]);

  if (isPending) {
    return <>Loading...</>;
  }

  if (isError) {
    return <>Something went wrong...</>;
  }

  return (
    <>
      <header className="vertical-center">
        <h1>quests</h1>
      </header>

      <div className="horizontal-center">
        <img className="top" src="/images/top-split-line.png" alt="" />
      </div>

      <div className="view-info-holder vertical-center">
        <article className="view-info">
          points will be credited automatically
          <br />
          after completing tasks
        </article>
      </div>

      <div className="quests-list">
        {notCompletedQuests.map((quest) => (
          <div className="horizontal-center" key={quest.id}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={false}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={onQuestCompleted}
            />
          </div>
        ))}
        {completedQuests.map((quest) => (
          <div className="horizontal-center" key={quest.id}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={true}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={() => {}}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default QuestsView;
