import { queryOptions } from '@tanstack/react-query';
import { getFrens, getQuests, getUserInfo } from './api';

export const userInfoOptions = (tgId: number) => {
  return queryOptions({
    queryKey: ['userInfo', tgId],
    queryFn: () => getUserInfo(tgId),
    staleTime: 10000,
  });
};

export const questsOptions = (telegramId: number) => {
  return queryOptions({
    queryKey: ['userQuest'],
    queryFn: () => getQuests(telegramId),
    staleTime: 1000,
  });
};

export const frensOptions = (tgId: number) => {
  return queryOptions({
    queryKey: ['frens', tgId],
    queryFn: () => getFrens(tgId),
    staleTime: 10000,
  });
};
