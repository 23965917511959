import './FrenInfo.css';

interface FrenInfoProps {
  position: number;
  name: string;
  //icon: string,
  hatCoins: number;
}

function FrenInfo(props: FrenInfoProps) {
  const getIconPath = () => {
    const value = Math.floor(Math.random() * 5) + 1;
    return `/images/avatar-${value}.png`;
  };

  return (
    <div className="fren-border">
      {props.position < 4 && <div className="fren-top-border"></div>}
      {props.position > 3 && (
        <img
          className="fren-pos-icon"
          src="/images/pos-mark.png"
          alt=""
        />
      )}

      <div className="fren-position-holder vertical-center">
        <span className="fren">{props.position}</span>
      </div>
      <div className="fren-avatar-holder vertical-center">
        <img
          className="fren-avatart"
          src={getIconPath()}
          alt=""
        />
      </div>
      <div className="fren-name-holder vertical-center">
        <span className="fren fren-name">{props.name}</span>
      </div>
      <div className="fren-coins-holder">
        <div>
          <img
            className="fren-coins-icon"
            src="/images/mini-mask-group.png"
            alt=""
          />
          <span className="fren">{props.hatCoins.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
}

export default FrenInfo;
