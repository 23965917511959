import './Popup.css';

function Popup() {
  return (
    <div id="popup">
      <div className="popup-border" />
      <div className="popup-content">
        <div id="popup-icon-holder">
          <div className="vertical-center">
            <div className="horizontal-center">
              <img
                id="popup-icon"
                alt=""
              />
            </div>
          </div>
        </div>
        <div id="popup-message-holder">
          <p id="popup-message" />
        </div>
      </div>
    </div>
  );
}

export function showPopup(message: string, icon?: string) {
  const popup = document.getElementById('popup');
  const popupMessage = document.getElementById('popup-message');
  const popupMessageHolder = document.getElementById('popup-message-holder');
  const popupIcon = document.getElementById('popup-icon') as HTMLImageElement;
  const popupIconHolder = document.getElementById('popup-icon-holder');
  if (!popup || !popupMessage || !popupMessageHolder || !popupIcon || !popupIconHolder) {
    return;
  }

  if (icon) {
    popupIcon.src = icon!;
    popupIcon.classList.remove('empty');
    popupIconHolder.classList.remove('empty');
    popupMessageHolder.classList.remove('full');
  } else {
    popupIcon.src = '';
    popupIcon.classList.add('empty');
    popupIconHolder.classList.add('empty');
    popupMessageHolder.classList.add('full');
  }
  popupMessage.innerText = message;
  popup.classList.add('show');
  setTimeout(
    () => {
      popup.classList.remove('show');
    },
    3000,
    popup
  );
}

export default Popup;
