// api

export type ApiResponse<T> = {
  success: boolean;
  message?: string;
  data?: T;
};

export interface UserData {
  telegramId: number;
  name: string;
  coins: number;
  lastClaim: string;
}

export interface QuestData {
  id: string;
  link: string;
  title: string;
  target: number;
  cost: number;
  completed: boolean;
  end?: string;
}

export interface QuestStatusRequest {
  telegramId: number;
  questId: string;
}

export interface AddCoinsRequest {
  telegramId: number;
  coins: number;
}

export interface UserRequest {
  telegramId: number;
}

export type UserResponse = ApiResponse<UserData>;
export type BalanceResponse = ApiResponse<number>;
export type ClaimResponse = ApiResponse<number>;
export type FrensResponse = ApiResponse<UserData[]>;
export type QuestsResponse = ApiResponse<QuestData[]>;
export type QuestCompletedResponse = ApiResponse<boolean>;

//

export enum QuestTarget {
  Website,
  Discord,
  YouTube,
  Instagram,
  Twitter,
  Telegram,
  Frens,
  Game,
  HatPoints,
}

export interface NavMenuItem {
  id: number;
  icon: string;
  name: string;
  callback: () => void;
}
