import { useQuery } from "@tanstack/react-query";

import "./FrensView.css"

import FrenInfo from "../../FrenInfo";
import { showPopup } from "../../Popup"

import { frensOptions } from "../../../data/queryOptions";

import { createInvitationLink } from "../../../utils/BotHelper";


const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function FrensView() {
    const { data: frens, isPending, isError } = useQuery(frensOptions(telegramId));

    const copyInvitation = () => {

        const url = createInvitationLink(telegramId);

        navigator.clipboard.writeText(
            `Join us and start earning your first HAT.\n\nComplete simple tasks, throw shurikens, and become the ultimate ninja in Play Hattori.\n${url}`
        );
        showPopup("Link copied to clipboard");
    };

    if (isPending) {
      return <>Loading...</>
    }

    if (isError) {
      return <>Something went wrong.</>
    }

    return (
        <>
            <header className="vertical-center">
                <h1>frens</h1>
            </header>

            <div className="horizontal-center">
                <img className="top" src="/images/top-split-line.png" alt=""/>
            </div>

            <div className="view-info-holder vertical-center">
                <article className="view-info">
                    score 10% of your friends<br/>
                    + an extra 2,5% from their referrals
                </article>
            </div>

            <div className="fren-list">
                { frens!.map((fren, index) =>
                    <FrenInfo position={index + 1} name={fren.name} hatCoins={fren.coins} key={fren.telegramId}/>
                )}
            </div>

            <div className="invite-button-holder horizontal-center">
                <button className="invite-button" onClick={copyInvitation}>INVITE A FREND</button>
            </div>
        </>
    );
}

export default FrensView;
