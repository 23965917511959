import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';

import './ProgressView.css'

import Timer from '../../Timer';

import { claimCoins } from '../../../data/api';
import { userInfoOptions } from "../../../data/queryOptions";

import { claim_pause } from '../../../config/config';

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;
const claimPause = parseInt(claim_pause!);

function ProgressView() {
    const { data: userInfo, isPending, isError, refetch: refetchUserInfo } = useQuery(userInfoOptions(telegramId));

    const [nextClaim, setNextClaim] = useState(createNextClaim(new Date(), claimPause));
    const [readyToClaim, setReadyToClaim] = useState(true);

    const claimCoinsMutation = useMutation({
        mutationFn: () => {
            return claimCoins(telegramId)
        },
        onSuccess: async (value) => {
            if (value === undefined) {
                throw new Error("undefined result");
            }
            setReadyToClaim(false);
            if (value > 0) {
                setNextClaim(createNextClaim(new Date(), value));
            }
            else {
                setNextClaim(createNextClaim(new Date(), claimPause));
                await loadUserInfo();
            }
        },
        onError: (error) => {
            console.error(`claimCoins error: ${error}`)
        }
    });

    // TODO: refactor | add sockets
    async function tryToClaim() {
        if (telegramId !== undefined) {
            if (readyToClaim) {
                claimCoinsMutation.mutate();
            }
        }
    }

    async function loadUserInfo() {
        return refetchUserInfo().then(data => data.data).then(data => {
            if (data) {
                setNextClaim(createNextClaim(new Date(data.lastClaim), claimPause));
            }
        })
    }

    function createNextClaim(time: Date, delta: number) {
        return new Date(time.getTime() + delta);
    }

    function waitForTimeOut() {
        setReadyToClaim(true);
    }

    if (isPending) {
        return <>Loading...</>
    }

    if (isError) {
        return <>Something went wrong...</>
    }

    return ( 
        <div onLoad={() => {
            if (userInfo) {
                setNextClaim(createNextClaim(new Date(userInfo.lastClaim), claimPause));
                setReadyToClaim(nextClaim < new Date());
            }
        }}>
            <header className="vertical-center">
                <h1>your progress</h1>
            </header>

            <div className="horizontal-center">
                <img className="top" src="/images/top-split-line.png" alt=""/>
            </div>

            <div className="wallet-holder">

                <div className="horizontal-center">
                    <img className="wallet" src="/images/wallet.png" alt=""/>
                </div>

                <div className="horizontal-center">
                    <h1>
                        <span className="wallet wallet-value">{userInfo!.coins.toLocaleString()}</span> <span className="wallet"> HAT</span>
                    </h1>
                </div>

                <div className="horizontal-center">
                    <img className="center" src="/images/little-split-line.png" alt=""/>
                </div>
            </div>

            <div className="horizontal-center">
                <button id="claim-button" className={readyToClaim ? "claim claim-ready" : "claim claim-waiting"} onClick={async () => await tryToClaim()}>
                    { readyToClaim
                        ? <p className="claim-text">Claim available</p>
                        : <p className="claim-text"><span>Claim in</span> <Timer time={nextClaim} showUnits={false} expirationMessage="" expireCallback={() => waitForTimeOut()}/> <img className="claim-icon" src="/images/icon-time.png" alt=""/></p> }
                </button>
            </div>
        </div>
    )
}

export default ProgressView;
