import { useState } from 'react';

import './MainPage.css';

import FrensView from './views/FrensView';
import LeaderboardView from './views/LeaderboardView';
import ProgressView from './views/ProgressView';
import QuestsView from './views/QuestsView';

import NavMenu from '../NavMenu';
import Popup from '../Popup';

import { NavMenuItem } from '../../data/types';

function MainPage() {
  const [selectedMenuItemId, selectMenuItemId] = useState(1);

  const menuItems: NavMenuItem[] = [
    {
      id: 1,
      name: 'Points',
      icon: '/images/home.png',
      callback: () => selectMenuItemId(1),
    },
    {
      id: 2,
      name: 'Tasks',
      icon: '/images/quests.png',
      callback: () => selectMenuItemId(2),
    },
    {
      id: 3,
      name: 'Frens',
      icon: '/images/Frens.png',
      callback: () => selectMenuItemId(3),
    },
    // {
    //     id: 4,
    //     name: "leaderboard",
    //     icon: "/images/leaderboard.png",
    //     callback: () => selectMenuItemId(4)
    // },
  ];

  return (
    <div className="page-root">
      <Popup />

      {selectedMenuItemId === 1 && <ProgressView />}
      {selectedMenuItemId === 2 && <QuestsView />}
      {selectedMenuItemId === 3 && <FrensView />}
      {/* { selectedMenuItemId === 4 && <LeaderboardView/> } */}

      <NavMenu items={menuItems} selectedItemId={selectedMenuItemId} />
    </div>
  );
}

export default MainPage;
