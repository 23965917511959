import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import MainPage from './components/pages/MainPage';
import WelcomePage from './components/pages/WelcomePage';

function App() {
  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    tg.expand();
    tg.setBackgroundColor('#000000');
  }, []);

  return (
    <div className="app-root">
      <Routes>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </div>
  );
}

export default App;
