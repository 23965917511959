import { useEffect, useState } from 'react';

const getDaysCount = (distance: number) => Math.floor(distance / (1000 * 60 * 60 * 24));
const getHoursCount = (distance: number) => Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
const getMinutesCount = (distance: number) => Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
const getSecondsCount = (distance: number) => Math.floor((distance % (1000 * 60)) / 1000);

interface TimerProps {
  time: Date;
  showUnits: boolean;
  expirationMessage: string;
  expireCallback: () => void;
}

function Timer(props: TimerProps) {
  const [distance, setDistance] = useState(props.time.getTime() - new Date().getTime());

  const [daysCount, setDaysCount] = useState(getDaysCount(distance));
  const [hoursCount, setHoursCount] = useState(getHoursCount(distance));
  const [minutesCount, setMinutesCount] = useState(getMinutesCount(distance));
  const [secondsCount, setSecondsCount] = useState(getSecondsCount(distance));

  function format(value: number) {
    return value.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  }

  useEffect(() => {
    const intervalId = setInterval(function () {
      const now = new Date().getTime();

      setDistance(props.time.getTime() - now);

      setDaysCount(getDaysCount(distance));
      setHoursCount(getHoursCount(distance));
      setMinutesCount(getMinutesCount(distance));
      setSecondsCount(getSecondsCount(distance));

      if (distance <= 0) {
        props.expireCallback();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [distance, props]);

  return (
    <span className="timer">
      {distance > 0 ? (
        <>
          {daysCount > 0 && (
            <span>
              {format(daysCount)}
              <span>{props.showUnits && 'D'}:</span>
            </span>
          )}
          {hoursCount > 0 && (
            <span>
              {format(hoursCount)}
              <span>{props.showUnits && 'H'}:</span>
            </span>
          )}
          {minutesCount > 0 && (
            <span>
              {format(minutesCount)}
              <span>{props.showUnits && 'M'}:</span>
            </span>
          )}
          <span>
            {format(secondsCount)}
            <span>{props.showUnits && 'S'}</span>
          </span>
        </>
      ) : (
        <span>{props.expirationMessage}</span>
      )}
    </span>
  );
}

export default Timer;
