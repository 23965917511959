import './NavMenu.css';

import { NavMenuItem } from '../data/types';

interface NavMenuProps {
  items: NavMenuItem[];
  selectedItemId: number;
}

function NavMenu(props: NavMenuProps) {
  const select = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: NavMenuItem) => {
    const selectedClassName = 'selected';

    let list = document.querySelector('#NavMenu')?.getElementsByTagName('li') as HTMLCollectionOf<HTMLLIElement>;
    for (const element of Array.from(list)) {
      element.classList.remove(selectedClassName);
    }

    let target = event.currentTarget;
    target.classList.add(selectedClassName);

    item.callback();
  };

  return (
    <footer>
      <img className="split-line" src="/images/bottom-split-line.png" alt="" />

      <div className="vertical-center">
        <ul id="NavMenu" className="horizontal-center">
          {props.items.map((item) => (
            <li
              className={props.selectedItemId === item.id ? 'selected menu-item' : 'menu-item'}
              onClick={(e) => select(e, item)}
              key={item.id}
            >
              <div className="vertical-center">
                <div className="horizontal-center">
                  <img className="menu-item-icon" src={item.icon} alt={item.name} />
                </div>
                <div className="horizontal-center">
                  <p className="menu-item-title">{item.name}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
}

export default NavMenu;
